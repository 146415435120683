import React from 'react'
import { Card, Button, Container, Row, Col } from "react-bootstrap"
import logoEmblem from "../../images/logoEmblemExamples.png"
import programming from "../../images/programming.png"
import Title from "../Globals/Title"




// try to use Img tool  from gatsby for speed and security, note for later

export default function Services() {
    return (
      <div className="serviceSerction">
        <Container>
          <Title title="Try Our Products Now" />
          <Row className="servicesRow">
            <Col className="cardServiceCol" xs={12} md={6} lg={6}>
              <Card>
                <Card.Header className="cardHeaderTitle">
                  Logo Design
                </Card.Header>
                <Card.Img variant="top" src={logoEmblem} alt= "logo emblem examples" />
                <Card.Body>
                  <Card.Title>Logo from $199</Card.Title>
                  <Card.Text>
                    Let a logo designer take care of your ideal logo. Our goals
                    is to design a logo that is simple with quality and
                    originality.
                  </Card.Text>
                  <ul>
                    <li>2 Logo Design Concepts</li>
                    <li>1 Designer</li>
                    <li>3 Rounds of Revisions</li>
                    <br></br>
                    <li>Fonts and Color Codes</li>
                    <li>
                      <strong>Full Logo Rights </strong>
                    </li>
                    <li>
                      Source Files -Transparent background on PNG, JPG with
                      Vector files on EPS and AI
                    </li>
                    <li>Get Logo concepts in 3 days for revision or approval </li>
                  </ul>
                  <Button variant="primary" className="ReservationButton"
                     href="https://calendly.com/logopwa">
                      Let's Reserve a <strong> Free Consultation</strong> Now
                    
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col className="cardServiceCol" xs={12} md={6} lg={6}>
              <Card>
                <Card.Header className="cardHeaderTitle">
                  Website Development
                </Card.Header>
                <Card.Img variant="top" src={programming} alt="Web development example"/>
                <Card.Body>
                  <Card.Title>Website starting from $999</Card.Title>
                  <Card.Text>
                    Let our team of designers and developers make your desire
                    web application.
                  </Card.Text>
                  <ul>
                    <li>1 Team of Developers and Designers</li>
                    <li>2 Rounds of Revisions</li>
                    <li>4 Pages</li>
                    <li>Web and Mobile Responsive</li>
                    <li>Get Website prototype in 3 days for revision or approval</li>
                  </ul>
                  <Button variant="primary" className="ReservationButton"
                    href="https://calendly.com/logopwa">
                      Let's Reserve a <strong> Free Consultation</strong> Now
                   
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
}


    // <Col xs={12} md={4} lg={4}>
    //         <Card style={{ width: "18rem" }}>
    //           <Card.Img variant="top" src={seo} />
    //           <Card.Body>
    //             <Card.Title>Seo Services</Card.Title>
    //             <Card.Text>
    //               Some quick example text to build on the card title and make up
    //               the bulk of the card's content.
    //             </Card.Text>
    //             <Button variant="primary">Go somewhere</Button>
    //           </Card.Body>
    //         </Card>
    //       </Col>